<template>
  <div class="pc-footer">
    <div class="pc-footer__container">
      <a href="https://pionercup.ru/">
        <img :src="themeImages['logo-inverse']" alt="pionercup-logo" class="pc-footer__logo" width="154">
      </a>
      <div class="pc-footer__links">
        <a href="https://pionercup.ru/shop">Мерч</a>
        <a href="https://pionercup.ru/journal">Журнал РС</a>
        <a href="https://pionercup.ru/rifles">Сервис винтовок</a>
        <a href="https://pionercup.ru/volunteering">Волонтерство</a>
        <a href="https://pionercup.ru/companies">Компаниям</a>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters('settings', ['theme', 'themeImages']),
  },
};
</script>

<style lang="scss" scoped>
@import './Footer.scss';
</style>
